<template>
  <div>
    <b-modal
      id="modal-edit-report"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      ok-variant="primary"
      centered
      size="lg"
      title="Esa Update Status"
      @ok="confirmEditBonusNewMember"
      @hidden="resetModal()"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <!-- form -->
      <validation-observer ref="editBonusNewMember">
        <b-form
          ref=""
          method="POST"
          class="auth-reset-password-form mt-2"
          @submit.prevent="validationFormBonusNewMember"
        >
          <!-- Field: Name -->
          <!-- <b-form-group label-for="name">
              <label class="mb-1"><span class="text-danger">*</span> Name</label>
              <validation-provider
                #default="{ errors }"
                name="limit"
                rules="required"
                hidden
              >
                <b-form-input
                  id="name"
                  v-model="bonusNewMemberData.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

          <!-- Field: User Name -->
          <!-- <b-form-group label-for="username">
              <label class="mb-1"
                ><span class="text-danger">*</span> User Name</label
              >
              <validation-provider
                #default="{ errors }"
                name="limit"
                rules="required"
                disabled
              >
                <b-form-input
                  id="username"
                  v-model="bonusNewMemberData.username"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

          <!-- Status -->
          <b-col cols="12">
            <b-form-group label-for="status">
              <label class="mb-1 d-block">Status</label>
              <!-- <b-form-radio
                  v-model="bonusNewMemberData.status"
                  name="status"
                  value="1"
                  inline
                >
                  Pending
                </b-form-radio> -->
              <b-form-radio
                v-model="bonusNewMemberData.status"
                name="status"
                value="2"
                inline
              >
                Approve
              </b-form-radio>
              <b-form-radio
                v-model="bonusNewMemberData.status"
                name="status"
                value="3"
                inline
              >
                Reject
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BImg,
  BFormSpinbutton,
  BFormRadio,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BFormFile,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import esaStoreModule from "./esaStoreModule";
/* // eslint-disable-next-line
  import 'quill/dist/quill.core.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
  
  import { quillEditor } from 'vue-quill-editor' */
// import paymentTypeStoreModule from './paymentTypeStoreModule'

export default {
  components: {
    BOverlay,
    BImg,
    BFormSpinbutton,
    BFormRadio,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BFormFile,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    reportDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bonusNewMemberData: {
        id: "",
        username: "",
        name: "",
        status: "",
      },
      show: false,
    };
  },
  watch: {
    async reportDetail(newVal) {
      if (newVal) {
        this.show = true;
        await this.fillBonusNewMemberData(newVal);
        this.show = false;
      }
    },
  },
  methods: {
    validationFormBonusNewMember() {
      this.$refs.editBonusNewMember.validate().then((success) => {
        if (success) {
          store
            .dispatch(
              "campaign-stake-amount-report/editEseUpdateStatus",
              this.bonusNewMemberData
            )
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === "00") {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || "success",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  this.$nextTick(() => {
                    setTimeout(() => {
                      this.$bvModal.hide("modal-edit-report");
                      this.resetModal();
                    }, "500");
                  });
                  this.$emit("refetch-data");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              }
            })
            .catch((error) => {
              const { response } = error;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Must be one of Approve or Reject" || response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    async fillBonusNewMemberData(data) {
      console.log(13123, data);
      this.bonusNewMemberData.id = data.id;
      this.bonusNewMemberData.username = data.username;
      this.bonusNewMemberData.name = data.name;
      //   this.bonusNewMemberData.user_id = data.user_id;
      //   this.bonusNewMemberData.email = data.email;
      //   this.bonusNewMemberData.name = data.name;

      /* this.bonusNewMemberData.content = data.content
              this.bonusNewMemberData.banner = ''
              this.bonusNewMemberData.showBanner = data.banner ? baseUrl + data.banner : null, */
      this.bonusNewMemberData.status = data.status;
    },
    resetModal() {
      this.$emit("update:reportDetail", null);
      this.bonusNewMemberData.id = "";
      this.bonusNewMemberData.name = "";
      this.bonusNewMemberData.username = "";
      /* this.bonusNewMemberData.content = ''
              this.bonusNewMemberData.banner = '' */
      this.bonusNewMemberData.status = "";
    },
    confirmEditBonusNewMember(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormBonusNewMember();
    },
  },
  setup() {
    // const CAMPAIGN_ESA_STORE_MODULE_NAME = "campaign-stake-amount-report";

    // // Register module
    // if (!store.hasModule(CAMPAIGN_ESA_STORE_MODULE_NAME))
    //   store.registerModule(CAMPAIGN_ESA_STORE_MODULE_NAME, esaStoreModule);

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(CAMPAIGN_ESA_STORE_MODULE_NAME))
    //     store.unregisterModule(CAMPAIGN_ESA_STORE_MODULE_NAME);
    // });
    // if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
    return {};
  },
};
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>
